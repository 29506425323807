// @flow
import React from 'react';
import Layout from '../../components/layout';
import {
  PagePreviewCard,
  PagePreviewCardDivider,
} from '../../components/page-preview-card';
import frontMatterMap from '../../data/front-matter';
import { withFrontMatter } from '../../components';
import type { FrontMatter } from '../../utils/types';

class Blog extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    const { frontMatter } = this.props;

    const previews = frontMatter.children.map((id) => {
      const child = frontMatterMap[id];
      return <PagePreviewCard key={child.id} frontMatter={child} />;
    });

    return (
      <Layout frontMatter={frontMatter} hideHorizontalRules hideFooter>
        <PagePreviewCardDivider />
        {previews}
      </Layout>
    );
  }
}

export default withFrontMatter('blog')(Blog);
